<template>
  <NuxtLayout>
    <div class="pb-12 mb-12 charter-start">
      <CharterHeader @search="([$event, param]) => doSearch($event, param)" />
      <CharterMobileMenu />
      <div class="w-full px-4 layout-container mx-auto">
        <Shoutouts class="my-6 lg:my-5 pt-6 pb-2 md:pb-0" />
        <PitchContainer class="mb-12 md:pb-4 md:pt-3" />
      </div>
      <div class="bg-light-turquoise py-6 md:py-5 mb-12">
        <div class="w-full px-4 layout-container mx-auto pb-6 pt-4 md:pt-0">
          <HydrateWhenVisible class="min-h-[600px] md:min-h-[500px]">
            <LazyCharterDestinations />
          </HydrateWhenVisible>
        </div>
      </div>
      <div class="w-full px-4 layout-container mx-auto">
        <div class="mb-12 pb-4 pr-2 md:pr-0">
          <HydrateWhenVisible class="min-h-[700px] md:min-h-[800px]">
            <LazyCharterStartpageLastMinute />
          </HydrateWhenVisible>
        </div>
        <HydrateWhenVisible class="min-h-[700px] md:min-h-[650px]">
          <LazyCharterStartpageSelected />
        </HydrateWhenVisible>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup>
const { urls: localeURLs } = useLocale()
const { t } = useI18n()

useHead({
  title: () =>  `${t('siteTitle')}`,
  meta: () => [
    {
      hid: 'description',
      name: 'description',
      content: () => `${t('homeDescription')}`,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: `${localeURLs.BASE_URL}`,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: `${t('siteTitle')} - ${t('siteTitleBrand')}`,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: formatCloudinaryImagePath('singlepage/startpage-solresor/solresor-parasol_wjhrfj', {
        forceJPG: true,
        isSol: true,
      }), // temp fix? startpage loaded afterwards
    },
    {
      hid: 'og:description',
      name: 'og:description',
      content: () => `${t('homeDescription')}`,
    },
    {
      name: 'google-site-verification',
      content: 'vCKb9vRFS3-tspc-TOjttHLY7S0A9wNQbWEXBLFm08E',
    },
  ],
})

const rootStore = useRootStore()
const charterStore = useCharterStore()
const charterPackagesStore = useCharterPackagesStore()
// const { showOfficeTools } = storeToRefs(rootStore)
// const { startpage } = storeToRefs(charterStore)

// const hotels = computed(() => {
//   if (this.$fetchState.pending) {
//     return [...new Array(3)].keys()
//   }
//   return startpage.value.promoted_hotels?.slice(0, 3) || []
// })

await useAsyncData(async () => {
  await Promise.all([
    rootStore.fetchCharterAirports(),
    rootStore.fetchCharterDestinations(),
    charterStore.fetchTagsFeatured(),
    charterStore.fetchStart(),
    charterPackagesStore.SET_LASTMINUTE(false),
    charterPackagesStore.getDestinations(),
  ])

  return {}
})

const doSearch = (params, type = 'charter') => {
  // const searchDisplay = 'CharterFixedSearch'
  const url = type === 'charter' ? localeURLs.chartersearch : localeURLs.packagessearch

  let options
  if (type === 'charter') {
    options = charterStore.getURLParameters(params)
  } else {
    options = charterPackagesStore.getURLParameters(params)
  }
  nextTick(() => {
    return navigateTo(`${url}?${options}`)
  })
  // return navigateTo(`${url}?${options}`)
}

// const shortPreamble = (text) => {
//   if (text.length > 159) {
//     return text.substr(0, 160) + '… '
//   } else {
//     return text
//   }
// }

// const getMovieSource = (id, type) => {
//   if (type === 'youtube') {
//     return `https://youtube.com/embed/${id}?rel=0?version=3controls=0&showinfo=0&autohide=1&fs=0&autoplay=1&loop=1&playlist=${id}`
//   } else if (type === 'vimeo') {
//     return `https://player.vimeo.com/video/${id}?autoplay=1&loop=1&autopause=0&api=1&controls=0&muted=1&playsinline=0`
//   } else {
//     return id
//   }
// }
</script>

<style lang="scss">
.box {
  &-title {
    font-family: theme('fontFamily.secondary');
    font-size: 3.4rem;
    font-weight: 900;
    line-height: 1.3em;

    @media (max-width: theme('screens.md')) {
      font-size: 2.5rem;
    }

    @media (max-width: theme('screens.sm')) {
      font-size: 2.2rem;
    }
  }

  &-caption {
    font-family: theme('fontFamily.fancy');
    font-size: 1.4rem;
    font-weight: 900;
    line-height: 36px;

    @media (max-width: theme('screens.md')) {
      font-size: 1.2rem;
      line-height: 30px;
    }
  }
}

.charter-start {
  .layout-container {
    max-width: $container-width;
  }

  &__usp {
    display: grid;
    grid-template-columns: 4fr 6fr 5fr;

    &-yellow {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;

      padding: 2rem;
      background: theme('colors.orange.DEFAULT');

      // border-top-right-radius: 15% 100%;
      // border-bottom-right-radius: 15% 100%;

      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        right: -0;
        height: 100%;
        min-width: 500px;
        width: 30vw;

        background: theme('colors.orange.DEFAULT');

        border-radius: 50%;

        transform: translate(27%, 0) rotate(-90deg);
      }
    }

    img {
      object-fit: cover;
      max-height: 450px;
    }

    @media (max-width: theme('screens.md')) {
      grid-template-areas: "a b"
                           "c c";

      grid-template-columns: 4fr 6fr;
      grid-template-rows: 1fr 1fr;

      &-yellow {
        z-index: 2;
      }
      &-image {
        grid-area: b;
        position: relative;
      }
      &-list {
        grid-area: c;
        position: relative;
        z-index: 2;
        background: white;
      }
    }

    @media (max-width: theme('screens.sm')) {
      grid-template-areas: "a" "b" "c";
      grid-template-columns: 1fr;

      &-yellow {
        &:after {
          display: none;
        }
      }
    }
  }
}
</style>
