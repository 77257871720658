// Migrated
<template>
  <ScreenWidthProvider
    v-slot="{ isNarrower }"
    class="flex flex-wrap"
  >
    <div class="w-12/12 lg:w-8/12 relative min-h-[400px] mx-0 mb-4 lg:mb-0 px-0">
      <div class="absolute size-full flex flex-col lg:pr-5 lg:pt-3 lg:pb-1">
        <div class="flex flex-wrap h-1/2">
          <div class="w-6/12 pr-[10px] h-full">
            <ResponsiveImage
              class="size-full"
              :image="images.smallFirst"
              alt=""
            />
          </div>
          <div class="w-6/12 pl-[10px] h-full">
            <ResponsiveImage
              class="size-full"
              :image="images.smallSecond"
              alt=""
            />
          </div>
        </div>
        <div class="flex flex-wrap h-1/2">
          <div class="w-12/12 pt-[20px] h-full">
            <ResponsiveImage
              class="size-full"
              :image="images.wide"
              alt=""
              :gravity="isNarrower('lg') ? 'auto' : null"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-12/12 lg:w-4/12">
      <ResponsiveText
        class="box-title text-shadow-none"
        data-i18n="solresorStartPage.pitchTitle"
      >
        <span>
          {{ $t('solresorStartPage.pitchTitle') }}
        </span>
      </ResponsiveText>
      <i-wave
        class="my-6 text-black pb-2 ml-4 pl-1 scale-x-40"
        filled
        :font-controlled="false"
        width="100px"
      />
      <p
        class="mb-0"
        data-i18n="solresorStartPage.pitchText"
      >
        {{ $t('solresorStartPage.pitchText') }}
      </p>
    </div>
  </ScreenWidthProvider>
</template>

<script>
export default defineNuxtComponent({
  data () {
    return {
      images: {
        smallFirst: 'solresor-startpage/small-1',
        smallSecond: 'solresor-startpage/small-2',
        wide: 'solresor-startpage/wide',
      },
    }
  },
})
</script>
