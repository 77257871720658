// Migrated
<template>
  <div class="flex -mx-4 flex-col md:flex-row">
    <div
      v-for="(shoutout, idx) in shoutouts"
      :key="idx"
      class="w-12/12 md:grow px-4"
    >
      <nuxt-link
        class="btn h-[50px] flex items-center justify-center h5 bg-baby-blue hover:bg-turquoise transition-all !text-dark w-full block mb-4 mb-md-0"
        :to="shoutout.button_relative_url"
      >
        {{ shoutout.button_text }}
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { rowTypes } from '@layers/web/constants/startpage.js'

export default defineNuxtComponent({
  computed: {
    ...mapState(useCharterStore, {
      startpage: 'startpage',
    }),

    shoutouts () {
      return (this.startpage?.rows || []).filter(({ type }) => type === rowTypes.shoutoutButton).slice(0, 4)
    },
  },
})
</script>
