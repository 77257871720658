// Migrated
<template>
  <div class="grow">
    <div class="charter-search">
      <PackageSearchAirportButton
        ref="charterButtonAirport"
        aria-haspopup="true"
        aria-labelledby="charterButtonLabelAirport"
        :is-active="activeSearch === 'airport'"
        :select-destination-first="selectDestinationFirst"
        :selected-airport="selectedAirport"
        @click="setActiveSearch('airport')"
      />
      <div
        id="charterButtonDestination"
        ref="charterButtonDestination"
        class="charter-search__btn"
        :class="{ active: activeSearch === 'destination' }"
        :aria-expanded="activeSearch === 'destination'"
        aria-haspopup="true"
        aria-labelledby="charterButtonLabelDestination"
        @click="setActiveSearch('destination')"
      >
        <div
          id="charterButtonLabelDestination"
          class="charter-search__btn-title"
        >
          <fa
            class="mr-2"
            :icon="icons.faMapLocationDot"
          />
          <span>
            {{ $t('charterSearchDestination') }}
          </span>
        </div>
        <div class="charter-search__btn-text">
          <span v-if="!selectedDestination">
            {{ $t('charterSearchSelect') }}
          </span>
          <span v-else>
            {{ selectedDestination.name }}
          </span>
          <fa
            class="charter-search__btn-icon"
            :icon="activeSearch === 'destination' ? 'caret-square-up' : 'caret-square-down'"
          />
        </div>
        <div
          v-if="selectAirportFirst"
          class="charter-search__btn-title text-error blink blink-title"
        >
          {{ $t('charterSelectAirportFirst') }}
        </div>
      </div>
      <div
        id="charterButtonDate"
        ref="charterButtonDate"
        class="charter-search__btn"
        :class="{ active: activeSearch === 'date' }"
        :aria-expanded="activeSearch === 'date'"
        aria-haspopup="true"
        aria-labelledby="charterButtonLabelDate"
        @click="setActiveSearch('date')"
      >
        <div
          id="charterButtonLabelDate"
          class="charter-search__btn-title"
        >
          <fa
            class="mr-2"
            :icon="icons.faCalendarAlt"
          />
          <span>
            {{ $t('charterSearchDate') }}
          </span>
        </div>
        <div class="charter-search__btn-text">
          <span v-if="!displayDateRange">
            {{ $t('charterSearchSelect') }}
          </span>
          <span v-else>{{ localeDate(displayDateRange.start) }}<small
            v-if="displayDateRange.end"
            class="block"
          >{{ $t('charterSearchDateUntil', { n: localeDate(displayDateRange.end) }) }}</small>
          </span>
          <fa
            class="charter-search__btn-icon"
            :icon="activeSearch === 'date' ? 'caret-square-up' : 'caret-square-down'"
          />
        </div>
        <div
          v-if="selectDestinationFirst"
          class="charter-search__btn-title text-error blink blink-title"
        >
          {{ $t('charterSelectDestinationFirst') }}
        </div>
        <div
          v-if="selectReturnDate"
          class="charter-search__btn-title text-error blink blink-title"
        >
          {{ $t('charterLegendSelectFlightDates') }}
        </div>
      </div>
      <CharterSearchButtonGuests
        :active="activeSearch === 'guests'"
        :display-rooms="displayRooms"
        :select-child-ages="selectChildAges"
        @set-active="setActiveSearch('guests')"
      />
      <div class="charter-search__btn-search">
        <button
          class="rounded-box btn-orange"
          type="button"
          @click="search"
        >
          {{ $t('searchButtonText') }}
        </button>
      </div>
    </div>
    <div
      id="charter-parent"
      class="relative"
    >
      <CharterAirportSelect
        key="charterWindowAirportParent"
        ref="charterWindowAirportParent"
        :active="activeSearch === 'airport'"
        :selected-airport="selectedAirport"
        :prefilled-selected-airport="prefilledSelectedAirport"
        :prefill="prefill"
        @close="setActiveSearch(null)"
        @select="selectAirport"
      />
      <transition-group
        name="grow-down-menu"
        tag="div"
      >
        <div
          v-if="activeSearch === 'destination'"
          id="charterWindowDestination"
          ref="charterWindowDestination"
          key="charterWindowDestination"
          class="charter-search__window"
        >
          <div class="charter-search__window-content overflow-visible">
            <div class="charter-search__header flex justify-between">
              <h5 class="mb-0">
                {{ $t('charterSelectDestination') }}<small
                  v-if="selectDestinationFirst"
                  class="ml-4 text-error blink blink-title block lg:hidden"
                >{{ $t('charterSelectDestinationFirst') }}</small>
              </h5>
              <button
                class="btn py-0 block lg:hidden m-0 text-[20px]"
                type="button"
                @click="setActiveSearch(null)"
              >
                <fa icon="times" />
              </button>
            </div>
            <div class="flex flex-wrap -mx-4 charter-search__list">
              <div
                v-for="(list, idx) in paginatedDestinations"
                :key="idx"
                class="md:w-4/12 px-4"
              >
                <div
                  v-for="(destinationL1, index) in list"
                  :key="`d${index}`"
                  class="charter-search__list-item"
                  :class="{ active: destinationL1.name === selectedDestinationL1 }"
                >
                  <a
                    v-if="destinationL1.destinations.length > 1"
                    class="btn flex justify-between w-full px-0"
                    :class="{ 'font-bold': destinationL1.name === selectedDestinationL1 }"
                    @click="selectDestinationL1(destinationL1.name)"
                  >{{ destinationL1.name }}
                    <fa
                      class="charter-search__btn-icon"
                      :icon="destinationL1.name === selectedDestinationL1 ? 'chevron-up' : 'chevron-down'"
                    />
                  </a>
                  <a
                    v-else
                    class="btn flex justify-between w-full px-0"
                    :class="{ 'font-bold': destinationL1.destinations[0] === selectedDestination }"
                    @click="selectDestination(destinationL1.destinations[0])"
                  >{{ destinationL1.name }}</a>
                  <ul
                    v-if="destinationL1.name === selectedDestinationL1"
                    class="pl-4"
                  >
                    <li
                      v-for="destination in destinationL1.destinations"
                      :key="destination.destination_id"
                    >
                      <button
                        type="button"
                        :class="{ active: destination === selectedDestination }"
                        @click="selectDestination(destination)"
                      >
                        {{ destination.name }}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                v-if="destinationCount < 20"
                class="md:w-12/12 px-4"
              >
                <div class="alert alert-info text-black mt-4">
                  <fa icon="exclamation-circle" />
                  <span class="ml-2">
                    {{ $t('charterLowDestinationWarning') }}
                  </span>
                </div>
              </div>
              <div class="w-12/12 md:w-6/12 lg:w-4/12 px-4 border-0 mt-4">
                <label
                  class="font-bold"
                  for="charterDestinationSearch"
                >{{ $t('charterGoAnywhere') }}</label>
                <GooglePlacesAutocomplete
                  :options="{ types: ['(cities)'] }"
                  :value="customDestinationName"
                  :prepend="prependDestinations"
                  @select-destination="selectDestination"
                  @result-changed="selectCustomDestination"
                  @result-cleared="selectCustomDestination(null)"
                >
                  <template #input="{ context, events, actions }">
                    <div
                      class="charter-search__places"
                      :class="{ active: !!customDestinationName }"
                    >
                      <input
                        id="charterDestinationSearch"
                        ref="search"
                        v-model="context.input"
                        class="form-control"
                        type="search"
                        :placeholder="$t('charterGoAnywherePlaceholder')"
                        autocomplete="off"
                        @focus="events.inputHasReceivedFocus"
                        @input="events.inputHasChanged"
                        @keydown.enter.prevent="actions.selectItemFromList"
                        @keydown.down.prevent="actions.shiftResultsSelection"
                        @keydown.up.prevent="actions.unshiftResultsSelection"
                      />
                    </div>
                  </template>
                  <template #item="{ place }">
                    <span class="cursor-pointer hover:bg-light-gray block p-2">
                      {{ place.description }}
                    </span>
                  </template>
                  <template #activeItem="{ place }">
                    <span class="cursor-pointer hover:bg-light-gray block p-2 orange-bg">
                      {{ place.description }}
                    </span>
                  </template>
                </GooglePlacesAutocomplete>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="activeSearch === 'date'"
          id="charterWindowDate"
          ref="charterWindowDate"
          key="charterWindowDate"
          class="charter-search__window"
        >
          <div class="charter-search__window-content">
            <h5 class="charter-search__header">
              {{ $t('charterSelectDate') }}
              <button
                class="btn py-0 block lg:hidden m-0 text-[20px]"
                type="button"
                @click="setActiveSearch(null)"
              >
                <fa icon="times" />
              </button>
            </h5>
            <client-only>
              <ScreenWidthProvider
                v-slot="{ isNarrower, isWider }"
                class="flex justify-center mb-12 mt-6"
              >
                <DatePicker
                  ref="charterFC"
                  v-model:range="selectedStartDate"
                  v-bind="calendarConfig"
                  :rows="isNarrower('md') ? 12 : isWider('md') && isNarrower('lg') ? 6 : 1"
                  @dayclick="calendarChoseDay"
                >
                  <template #day-content="{ day, dayProps, dayEvents }">
                    <button
                      class="bg-transparent p-0 size-full flex items-center justify-center relative"
                      v-bind="dayProps"
                      :class="{ 'opacity-50': day.isDisabled }"
                      :disabled="day.isDisabled"
                      v-on="dayEvents"
                    >
                      {{ day.day }}<small
                        v-if="directFlightDay(day)"
                        class="absolute right-0 top-0 direct-flight-day"
                      >
                        <fa :icon="icons.faPlane" />
                      </small>
                    </button>
                  </template>
                </DatePicker>
              </ScreenWidthProvider>
              <div
                v-if="window > 768 && (directFlightDays.length || (selectedStartDate.dateRange.start && !selectedStartDate.dateRange.end))"
                class="border-box mt-4"
              >
                <transition
                  name="fade"
                  mode="out-in"
                >
                  <div
                    v-if="selectedStartDate.dateRange.start && !selectedStartDate.dateRange.end"
                    class="btn-orange-transparent p-1 legend-text blink"
                  >
                    {{ $t('charterLegendSelectReturnFlight') }}
                  </div>
                </transition>
                <div v-if="directFlightDays.length">
                  <fa :icon="icons.faPlane" />
                  <span class="ml-2">
                    {{ $t('charterLegendDirectFlight') }}
                  </span>
                </div>
              </div>
              <div
                v-else-if="window <= 768"
                class="charter-search__fixed-bottom"
              >
                <p
                  v-if="selectedStartDate.dateRange.start && !selectedStartDate.dateRange.end"
                  class="btn-orange-transparent p-1 blink"
                >
                  {{ $t('charterLegendSelectReturnFlight') }}
                </p>
                <div
                  v-if="directFlightDays.length"
                  class="border-box mt-4"
                >
                  <fa :icon="icons.faPlane" />
                  <span class="ml-2">
                    {{ $t('charterLegendDirectFlight') }}
                  </span>
                </div>
              </div>
            </client-only>
          </div>
        </div>
      </transition-group>
      <LazyCharterSearchWindowGuests
        v-if="activeSearch === 'guests'"
        key="charterWindowGuestsParent"
        :rooms="rooms"
        :passengers-full="passengersFull"
        @clear="setActiveSearch(null)"
        @update="updateRooms"
        @search="search"
      />
    </div>
    <LazyCharterBookingErrorModal
      :errors="tooManyInfants"
      :no-close-on-esc="false"
      :close-self="true"
    >
      <template #text>
        <span>
          {{ $t('charterSearchTooManyInfants') }}
        </span>
      </template>
    </LazyCharterBookingErrorModal>
  </div>
</template>

<script>
import { faPlane, faPlaneDeparture, faMapLocationDot, faCalendarAlt, faUserFriends } from '@fortawesome/pro-duotone-svg-icons'
import { mapActions, mapState } from 'pinia'
import { DATEPICKER_DATE_FORMAT } from '@packages/date/formats'

export default defineNuxtComponent({
  setup () {
    const { localeDate } = useDate()

    return {
      localeDate,
    }
  },

  props: {
    autofocus: {
      type: Boolean,
      default: true,
    },

    header: {
      type: Boolean,
      default: false,
    },

    prefill: {
      type: Object,
      default: null,
    },

    prefilledSelectedAirport: {
      type: Object,
      default: null,
    },

    doSearch: {
      type: Boolean,
      default: true,
    },

    maxCalendars: {
      type: Number,
      default: 3,
    },
  },

  emits: ['selectAirport', 'search'],

  data () {
    return {
      icons: {
        faPlane,
        faPlaneDeparture,
        faMapLocationDot,
        faCalendarAlt,
        faUserFriends,
      },

      query: this.$route.query.q,

      selectedAirport: null,
      selectedDestination: null,
      selectedDestinationL1: null,
      ageArray: [...Array(18).keys()],
      selectedStartDate: {
        'currentDate': null,
        'selectedDate': false,
        'selectedDateTime': false,
        'selectedHour': '00',
        'selectedMinute': '00',
        'selectedDatesItem': '',
        'selectedDates': [],
        'dateRange': {
          'start': '',
          'end': '',
        },

        'multipleDateRange': [],
      },

      activeSearch: null,
      selectDestinationFirst: false,
      selectAirportFirst: false,
      selectReturnDate: false,
      selectChildAges: false,
      tooManyInfants: false,

      window: 0,
      timer: null,
    }
  },

  computed: {
    ...mapState(useRootStore, {
      airports: 'charterAirports',
      destinations: 'charterDestinations',
    }),

    ...mapState(useCharterStore, {
      calendarSuggestions: 'calendarSuggestions',
    }),

    ...mapState(useCharterPackagesStore, {
      rooms: 'rooms',
      getNoOfPassengers: 'getNoOfPassengers',
      overMaxNoOfPassengers: 'overMaxNoOfPassengers',
      roomsHaveEmptyAgeValues: 'roomsHaveEmptyAgeValues',
      peoplePerRoom: 'peoplePerRoom',
    }),

    filteredAirports () {
      return this.airports.filter(a => a.airport !== 'ZZZ')
    },

    normalAirports () {
      return this.filteredAirports.filter(a => !a.featured_airport)
    },

    featuredAirports () {
      return this.filteredAirports.filter(a => !!a.featured_airport)
    },

    filteredDestinations () {
      const ap = this.selectedAirport?.airport

      return this.destinations.map(d1 => ({
        ...d1,
        destinations: d1.destinations.filter(d => !!d.avalible_from_airports?.includes(ap)),
      })).filter(d => d.destinations.length > 0)
    },

    prependDestinations () {
      return this.filteredDestinations.flatMap(d => d.destinations.map(dd => ({
        ...dd,
        country: d.name,
      })))
    },

    paginatedDestinations () {
      return [
        this.filteredDestinations.slice(0, Math.ceil(this.filteredDestinations.length / 3)),
        this.filteredDestinations.slice(Math.ceil(this.filteredDestinations.length / 3), Math.ceil(this.filteredDestinations.length / 3) * 2),
        this.filteredDestinations.slice(Math.ceil(this.filteredDestinations.length / 3) * 2, Math.ceil(this.filteredDestinations.length)),
      ]
    },

    destinationCount () {
      return this.filteredDestinations.reduce((acc, curr) => {
        return acc + curr.destinations.length
      }, 0)
    },

    elemId () {
      return this.header ? 'articles-search-header' : 'articles-search'
    },

    calendarsCount () {
      return this.window > 1599 ? this.maxCalendars :
        this.window > 920 ? 2 :
          this.window <= 768 ? 12 : 1
    },

    calendarConfig () {
      return {
        color: 'solresor',
        borderless: true,
        showWeeknumbers: true,
      }
    },

    displayDateRange () {
      if (this.selectedStartDate.dateRange && this.selectedStartDate.dateRange.start) {
        return this.selectedStartDate.dateRange
      }

      return null
    },

    displayRooms () {
      return this.peoplePerRoom
    },

    /**
     * Max number of passengers is 9, regardless of age
    */
    passengersFull () {
      return this.overMaxNoOfPassengers
    },

    customDestinationName () {
      return this.selectedDestination && this.selectedDestination.custom ? this.selectedDestination.formatted_address : null
    },

    directFlightDays () {
      if (!this.selectedStartDate.dateRange.start) {
        return this.calendarSuggestions.outbound
      }
      return this.calendarSuggestions.homebound
    },

    forcedCalendarDays () {
      if (!this.calendarSuggestions.forced) {
        return []
      }

      if (!this.selectedStartDate.dateRange.start) {
        return Object.keys(this.calendarSuggestions.outbound).map(d => this.$dayjs(d).format(DATEPICKER_DATE_FORMAT))
      }
      if (!this.selectedStartDate.dateRange.end) {
        return Object.keys(this.calendarSuggestions.homebound).map(d => this.$dayjs(d).format(DATEPICKER_DATE_FORMAT))
      }

      return Object.keys(this.calendarSuggestions.outbound).map(d => this.$dayjs(d).format(DATEPICKER_DATE_FORMAT))
    },
  },

  watch: {
    prefilledSelectedAirport (v) {
      if (!v) {
        return
      }
      this.selectedAirport = v
    },
  },

  mounted () {
    window.addEventListener('resize', this.resizeListener)

    this.window = window.innerWidth

    if (this.prefill) {
      this.selectedDestination = this.prefill.selectedDestination
      this.selectedDestinationL1 = this.prefill.selectedDestinationL1
      this.selectedStartDate = this.prefill?.selectedStartDate || this.selectedStartDate
      if (this.prefill?.rooms) {
        this.setRooms(this.prefill.rooms)
      }
    }
  },

  beforeUnmount () {
    clearTimeout(this.timer)
    window.removeEventListener('resize', this.resizeListener)
  },

  methods: {
    ...mapActions(useCharterPackagesStore, {
      setSelectedRooms: 'SET_SELECTED_ROOMS',
      setRooms: 'SET_ROOMS',
    }),

    ...mapActions(useCharterStore, {
      setCalendarSuggestions: 'SET_CALENDAR_SUGGESTIONS',
      getCalendarSuggestions: 'getCalendarSuggestions',
      charterSearch: 'search',
    }),

    resizeListener () {
      this.window = window.innerWidth
    },

    search () {
      if (!this.selectedAirport) {
        return this.setActiveSearch('airport')
      }
      if (!this.selectedDestination) {
        return this.setActiveSearch('destination')
      }
      if (!this.selectedStartDate.dateRange.start || !this.selectedStartDate.dateRange.end) {
        if (!this.selectedStartDate.dateRange.end) {
          this.selectReturnDate = false
          this.$nextTick(() => {
            this.selectReturnDate = true
          })
        }
        return this.setActiveSearch('date')
      }
      if (this.roomsHaveEmptyAgeValues) {
        this.selectChildAges = false
        this.$nextTick(() => {
          this.selectChildAges = true
          this.setActiveSearch('guests')
        })
        return
      }

      if (this.peoplePerRoom.infants > this.peoplePerRoom.adults) {
        this.tooManyInfants = true
        return
      }

      const params = {
        selectedAirport: this.selectedAirport,
        selectedDestination: this.selectedDestination,
        selectedDestinationL1: this.selectedDestinationL1,
        rooms: this.rooms,
        selectedStartDate: this.selectedStartDate,
      }
      this.$emit('search', params)

      // Prevent double calling search with redirects to search page that calls search onmount
      if (this.doSearch) {
        this.charterSearch(params)
      }

      this.setActiveSearch(null)
    },

    setActiveSearch (search) {
      this.activeSearch = this.activeSearch === search ? null : search

      if (this.activeSearch && this.window <= 768) {
        window.document.body.classList.add('sideMenuActive')
        window.document.documentElement.classList.add('sideMenuActive')
      } else {
        window.document.body.classList.remove('sideMenuActive')
        window.document.documentElement.classList.remove('sideMenuActive')
      }

      this.selectDestinationFirst = false
      this.selectAirportFirst = false

      if ((this.activeSearch === 'date' || this.activeSearch === 'destination') && !this.selectedAirport) {
        this.activeSearch = 'airport'
        this.selectAirportFirst = true
      } else if (this.activeSearch === 'date' && !this.selectedDestination) {
        this.activeSearch = 'destination'
        this.selectDestinationFirst = true
      } else if (search === 'guests' && this.selectChildAges) {
        this.activeSearch = 'guests'
      }

      this.$nextTick(() => {
        let pop = null
        switch (this.activeSearch) {
          case 'airport':
            pop = this.$refs.charterWindowAirport
            break
          case 'destination':
            pop = this.$refs.charterWindowDestination
            break
          case 'date':
            pop = this.$refs.charterWindowDate
            break
          case 'guests':
            pop = this.$refs.charterWindowGuests
            break
        }

        if (!pop) {
          return
        }

        this.timer = window.setTimeout(() => {
          this.$nextTick(() => {
            const rect = pop.getBoundingClientRect?.()

            const offset = 0 // 50

            // document.documentElement.clientHeight instead of window.innerHeight because of iOS
            if (rect.top + pop.scrollHeight + offset < window.pageYOffset + document.documentElement.clientHeight) {
              return
            }

            try {
              window.scrollTo({
                top: rect.top + pop.scrollHeight + offset - document.documentElement.clientHeight,
                behavior: 'smooth',
              })
            } catch {
              window.scrollTo(0, rect.top + pop.scrollHeight + offset - document.documentElement.clientHeight)
            }
          })
        }, 50)
      })
    },

    selectAirport ([airport, fullUpdate]) {
      this.$emit('selectAirport', airport)
      this.selectedAirport = airport

      if (fullUpdate) {
        this.loadSuggestions()
        this.timer = window.setTimeout(() => {
          this.setActiveSearch('destination')
        }, 200)
      }
    },

    selectDestinationL1 (index) {
      this.selectedDestinationL1 = this.selectedDestinationL1 === index ? null : index
    },

    selectDestination (destination) {
      this.selectedDestination = destination
      this.loadSuggestions()
      this.timer = window.setTimeout(() => {
        this.setActiveSearch('date')
      }, 200)
    },

    selectCustomDestination (destination) {
      if (!destination || !destination.geometry) {
        this.selectedDestination = null
        return
      }

      this.selectedDestination = {
        ...destination,
        name: destination.formatted_address,
        custom: true,
        latitude: destination.geometry.location.lat(),
        longitude: destination.geometry.location.lng(),
      }

      this.timer = window.setTimeout(() => {
        this.setActiveSearch('date')
      }, 200)

      this.loadSuggestions()
    },

    updateRooms ({ rooms, emptyAgeValues }) {
      this.setRooms(rooms)
      this.selectChildAges = emptyAgeValues
    },

    calendarChoseDay (day, switchToGuests = true) {
      if (day.date === this.selectedStartDate.dateRange.start && this.selectedStartDate.dateRange.end) {
        // Refuse click on same day
        this.selectedStartDate.dateRange.end = ''
        this.selectReturnDate = true
        return
      }

      if (this.selectedStartDate.dateRange.end) {
        this.selectReturnDate = false
      }

      if (switchToGuests && this.selectedStartDate.dateRange.end) {
        this.timer = window.setTimeout(() => {
          this.setActiveSearch('guests')
        }, 200)
      }
    },

    directFlightDay (day) {
      if (this.directFlightDays[this.localeDate(day.date)]) {
        return true
      }

      return false
    },

    async loadSuggestions () {
      this.setCalendarSuggestions({
        forced: false,
        homebound: {},
        outbound: {},
      })

      if (!this.selectedDestination || !this.selectedAirport) {
        return
      }

      const destination = this.selectedDestination.custom ? {
        latitude: this.selectedDestination.latitude,
        longitude: this.selectedDestination.longitude,
      } : { destination_id: this.selectedDestination.destination_id }

      try {
        await this.getCalendarSuggestions({
          airport: this.selectedAirport.airport,
          ...destination,
        })
      } catch (e) {
        this.$sentrySetContext('data', JSON.stringify({ error: e?.data || e }))
        this.$sentryCaptureException('charter/getCalendarSuggestions error', {
          level: 'warning',
          tags: {
            type: 'UX',
          },
        })
      }
    },
  },
})
</script>

<style lang="scss">
.blink {
  animation: blink 1.22s;
  background: theme('colors.orange-transparent');

  &-title {
    background: transparent;
  }
}

@keyframes blink {
  0% {
    background: transparent;
  }

  20%,
  80% {
    background: theme('colors.orange.DEFAULT');
  }

  50% {
    background: theme('colors.orange-transparent');
  }
}
</style>
