// Migrated
<template>
  <div class="relative">
    <div class="relative z-[0]">
      <CharterHeaderWaves
        class="absolute right-0 top-0 z-[3] -mt-2 -mr-5 sm:-mr-3 sm:mt-2 md:mr-2 lg:m-4 xl:m-5"
        title="solresorStartPage.wavesTitle"
        subtitle="solresorStartPage.wavesSubtitle"
        caption="solresorStartPage.wavesCaption"
      />
      <Swiper
        class="solresor-head-start"
        :autoplay="{ delay: 10000 }"
        loop
        constant-display-navigation
        arrows-type="special"
        pagination
        dots-align="above-bottom"
        :loading-skeleton-aspect="false"
        loading-skeleton-height="100%"
        prevent-swiper-update
        only-render-visible-slides
        arrows-size="xl"
      >
        <ResponsiveImage
          v-for="hero in startpage.heroes"
          :key="hero.id"
          class="h-full"
          :image="hero.image_cloudinary"
          :alt="hero.button_text"
          :lazy="false"
        >
          <div class="items-end px-12 xl:px-0 h-full flex -mx-3 sm:mx-0">
            <div class="w-full px-4 layout-container mx-auto solresor-head-start__box relative">
              <h1 class="solresor-head-start__box-title mb-4 md:mb-4 whitespace-pre-line">
                {{ toUnixNewlines(hero.text) }}
              </h1>
              <a
                v-if="hero.button_text"
                class="btn-rounded btn-xl uppercase font-medium btn-orange inline-block"
                :href="hero.button_link"
              >{{ hero.button_text }}</a>
            </div>
          </div>
        </ResponsiveImage>
      </Swiper>
    </div>
    <div class="w-full px-4 layout-container mx-auto relative solresor-head-start__searchbar z-[5]">
      <div
        v-if="showOfficeTools"
        class="pl-4 sm:pl-5 flex sm:block justify-center px-0"
      >
        <button
          id="CharterSearchBarButton"
          class="btn btn-solresor"
          type="button"
          :class="{ 'btn-solresor-active': search === 'CharterSearchBar' }"
          @click="search = 'CharterSearchBar'"
        >
          {{ $t('homeSearchCharterTrips') }}
        </button>
        <button
          id="RoundtripSearchBarButton"
          class="btn btn-solresor ml-1 sm:ml-3"
          type="button"
          :class="{ 'btn-solresor-active': search === 'PackageSearchBar' }"
          @click="search = 'PackageSearchBar'"
        >
          {{ $t('homeSearchPackages') }}
        </button>
      </div>
      <CharterSearchBar
        v-if="showOfficeTools && search === 'CharterSearchBar'"
        class="px-0"
        :do-search="false"
        :prefill="searchPrefill"
        @search="$emit('search', [$event, 'charter'])"
      />
      <PackageSearchBar
        v-if="search === 'PackageSearchBar'"
        class="px-0"
        :do-search="false"
        :prefill="searchPackagePrefill"
        @search="$emit('search', [$event, 'packages'])"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  emits: ['search'],

  data () {
    return {
      search: 'PackageSearchBar',
    }
  },

  computed: {
    ...mapState(useCharterStore, {
      startpage: 'startpage',
      selectedAirportState: 'selectedAirport',
      selectedDestination: 'selectedDestination',
      selectedDestinationL1: 'selectedDestinationL1',
      rooms: 'rooms',
      selectedStartDate: 'selectedStartDate',
    }),

    ...mapState(useRootStore, {
      showOfficeTools: 'showOfficeTools',
    }),

    ...mapState(useCharterPackagesStore, {
      selectedDateRangeStartDate: 'selectedDateRangeStartDate',
      selectedDateRangeEndDate: 'selectedDateRangeEndDate',
      rooms: 'rooms',
      selectedDestinations: 'selectedDestinations',
      selectedDestinationsL1: 'selectedDestinationsL1',
      selectedCategory: 'selectedCategory',
      selectedAirportPackageState: 'selectedAirport',
    }),

    searchPrefill () {
      return {
        selectedAirport: this.selectedAirportState,
        selectedDestination: this.selectedDestination,
        selectedDestinationL1: this.selectedDestinationL1,
        rooms: deepClone(this.rooms),
        selectedStartDate: deepClone(this.selectedStartDate),
      }
    },

    searchPackagePrefill () {
      return {
        selectedAirport: this.selectedAirportPackageState,
        // selectedDuration: this.selectedDuration,
        selectedCategory: this.selectedCategory,
        selectedDestinations: this.selectedDestinations,
        selectedDestinationsL1: this.selectedDestinationsL1,
        rooms: deepClone(this.rooms),
        selectedDateRangeStartDate: this.selectedDateRangeStartDate,
        selectedDateRangeEndDate: this.selectedDateRangeEndDate,
      }
    },
  },

  methods: {
    toUnixNewlines,
  },
})
</script>

<style lang="scss">
.solresor-head-start {
  height: 700px;
  min-height: 25rem;
  width: 100%;

  .swiper {
    &-pagination {
      bottom: 92px !important;
    }

    &-carousel-arrow {
      $offset: -30px;

      &-prev {
        left: $offset !important;
      }
      &-next {
        right: $offset !important;
      }
    }
  }

  .box-title {
    line-height: 80px;
  }

  @media (max-width: theme('screens.lg')) {
    height: 665px;
  }

  @media (max-width: calc(theme('screens.lg') - 1px)) {
    height: 550px;
  }

  @media (max-width: theme('screens.md')) {
    .box-title {
      line-height: 66px;
    }
  }

  @media (max-width: theme('screens.sm')) {
    height: 450px;
    min-height: 375px;

    .box-title {
      line-height: 56px;
    }
  }

  &__box {
    padding-bottom: 120px;

    @media (max-width: theme('screens.lg')) {
      width: auto;
    }

    @media (max-width: theme('screens.sm')) {
      padding-bottom: 120px;
    }

    &-title {
      font-family: theme('fontFamily.secondary');
      font-size: 4.7rem;
      font-weight: 900;
      line-height: 92px;
      color: theme('colors.white');
      text-shadow: 2px 2px 12px rgba(0,0,0,0.3);

      @media (max-width: theme('screens.lg')) {
        font-size: 4rem;
        line-height: 80px;
      }

      @media (max-width: theme('screens.md')) {
        font-size: 3rem;
        line-height: 66px;
      }

      @media (max-width: theme('screens.sm')) {
        font-size: 2.15rem;
        line-height: 46px;
      }
    }
  }

  &__searchbar {
    margin-top: -70px;
  }
}
</style>
