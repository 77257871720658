// Migrated
<template>
  <div class="inline-flex flex-col items-center text-white charter-header-waves">
    <h4
      class="inline uppercase font-light tracking-widest mb-1"
      :data-i18n="title"
    >
      {{ $t(title) }}
    </h4>
    <h3
      class="inline uppercase tracking-[.125em] mb-1"
      :data-i18n="subtitle"
    >
      {{ $t(subtitle) }}
    </h3>
    <div class="flex items-center justify-center w-full charter-header-waves-caption">
      <i-wave
        width="50px"
        filled
        :font-controlled="false"
      />
      <span
        class="text-nowrap px-2 font-light tracking-widest"
        :data-i18n="caption"
      >{{ $t(caption) }}</span>
      <i-wave
        width="50px"
        filled
        :font-controlled="false"
      />
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      required: true,
    },

    caption: {
      type: String,
      default: null,
    },
  },
})
</script>

<style lang="scss" scoped>
.charter-header-waves {
  h4 {
    font-size: 1.6rem;
  }

  @media (max-width: theme('screens.lg')) {
    transform: scale(.9);
  }

  @media (max-width: theme('screens.md')) {
    transform: scale(.7);
  }

  @media (max-width: theme('screens.sm')) {
    transform: scale(.6);
  }
}
</style>
